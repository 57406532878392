body {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  font-family: 'Inter';
}
/* css for posthog notification bar */
.notification-bar-container {
  min-height: 74px !important;
}
.alert-box {
  display: flex;
  background-color: #eaf4ff;
  padding: 16px;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 395px;
  gap: 8px;
}

.alert-text {
  flex: 1;
  text-align: start;
}

.alert-link {
  text-decoration: none;
}



